<template>
  <v-card v-if="request" flat>
    <v-card-text class="pa-10">
      <v-row no-gutters>
        <!-- ASSIGNEE -->
        <v-col>
          <div :class="labelClass">Assignee:</div>
          <Assignee :request="request" />
        </v-col>
        <!-- CLIENT -->
        <v-col cols="2">
          <div :class="labelClass">Client:</div>
          <div>
            <v-menu open-on-hover offset-y>
              <template #activator="{ on }">
                <div v-on="on">
                  <span>
                    {{ $store.getters["users/user"](request.user).fullName }}
                  </span>
                </div>
              </template>
              <v-list dense>
                <v-list-item>
                  <v-list-item-avatar class="overflow-visible mr-2" rounded>
                    <UserPhoto
                      :size="40"
                      photoSize="thumb"
                      :id="request.user"
                      rounded
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <a
                        :href="`mailto:${$store.getters['users/user'](request.user).email}`"
                        class="text-decoration-none d-block mb-2"
                      >
                        {{ $store.getters["users/user"](request.user).email}}
                      </a>
                      <template v-if="$store.getters['users/user'](request.user).requestPackages && $store.getters['users/user'](request.user).requestPackages.length">
                        <v-chip
                          v-for="pkg in $store.getters['users/user'](request.user).requestPackages"
                          class="ml-1 grey--text"
                          :key="pkg.id"
                          outlined
                          x-small
                          label
                        >
                          {{ $store.getters["request/rPackage"](pkg).name }}
                        </v-chip>
                      </template>
                      <v-chip
                        v-else-if="$store.getters['request/rPackage']($store.getters['users/user'](request.user).requestPackage)"
                        class="ml-1 grey--text"
                        outlined
                        x-small
                        label
                      >
                        {{ $store.getters["request/rPackage"]($store.getters["users/user"](request.user).requestPackage).name }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <template v-if="company_profile">
                  <v-list-item>
                    <v-list-item-title class="text--disabled overline">Company Profile</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="profileDialog = true">
                    <v-list-item-title class="text--secondary">
                      {{ company_profile.name }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <!-- TYPE -->
        <v-col>
          <div :class="labelClass">Type:</div>
          <div>
            {{ $store.getters["types/getProductName"](request.requestType) }}
          </div>
        </v-col>
        <!-- CREATED -->
        <v-col>
          <div :class="labelClass">Created:</div>
          <div>
            {{ request.created | formatDate }}
          </div>
        </v-col>
        <!-- DEADLINE -->
        <v-col>
          <div :class="labelClass">Deadline:</div>
          <div v-if="request.dueDate" class="d-inline">
            <span>
              {{
                request.dueDate
                  ? `${$options.filters.formatDate(request.dueDate)}`
                  : "TBA"
              }}
            </span>
            <span
              v-if="
                request.dueDate &&
                moment(request.dueDate.toDate()).diff(moment(), 'hours') >
                  -24 &&
                moment(request.dueDate.toDate()).diff(moment(), 'hours') < 0
              "
              class="info--text"
            >
              today
            </span>
            <span
              v-else-if="request.dueDate"
              class="ml-1"
              :class="
                $overdue(request) ? 'error--text' : 'grey--text lighten-2'
              "
            >
              {{ moment(request.dueDate.toDate()).fromNow() }}
            </span>
          </div>
          <span v-else>TBA</span>

          <v-menu v-if="$store.getters['request/canManage']" offset-y left>
            <template #activator="{ on }">
              <v-btn class="ml-1" v-on="on" small icon>
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-date-picker
                v-model="date"
                @change="setDeliveryDate"
                class="mt-4"
              ></v-date-picker>
            </v-card>
          </v-menu>
        </v-col>
        <!-- FOLLOWERS -->
        <v-col>
          <div :class="labelClass">Followers:</div>
          <Followers :request="request" />
        </v-col>
      </v-row>

      <v-divider class="my-5"></v-divider>

      <v-sheet max-width="750" color="">
        <div
          v-for="(entry, i) in orderBy(request.entries, 'order', 'asc')"
          :key="i"
        >
          <template v-if="entry.type == 'header'">
            <div class="mb-6">
              <h5 class="font-weight-bold" style="font-size: 18px">
                {{ entry.label }}
              </h5>
              <div
                class="subtle-text"
                v-if="entry.description"
                style="font-size: 16px"
              >
                {{ entry.description }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="mb-6">
              <div class="mb-1">
                {{ entry.label }}
                <div v-if="entry.description" class="font-weight-medium">
                  {{ entry.description }}
                </div>
              </div>
              <div
                v-if="entry.value && !Array.isArray(entry.value)"
                v-html="$options.filters.linkify(entry.value)"
                class="grey--text"
              ></div>
              <div
                v-else-if="Array.isArray(entry.value)"
                class="text-pre-wrap subtitle-1"
              >
                {{ entry.value.join("\n") }}
              </div>

              <div v-if="entry.type == 'attachment'" class="d-flex gap-7">
                <div
                  v-if="
                    $store.getters['request/getAttachments'](entry.order).length
                  "
                  class="d-flex gap-7 mt-2 flex-wrap"
                >
                  <file-block
                    v-for="file in $store.getters['request/getAttachments'](
                      entry.order
                    )"
                    :key="file.id"
                    :file="file"
                  />
                </div>
                <div v-else class="grey--text">No attached file</div>
              </div>
            </div>
          </template>
        </div>
      </v-sheet>
    </v-card-text>

    <!-- BRAND PROFILE -->
    <v-dialog :max-width="$dialog.medium" v-model="profileDialog" scrollable>
      <close-btn @click="profileDialog = false" overflow />

      <v-card>
        <v-card-text class="pt-7">
          <BrandProfile
            v-if="company_profile"
            :id="company_profile.id"
            readonly
            noHeader
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// PLUGINS
import moment from 'moment'
import firebase from 'firebase'
import db from '@/firebase/init'
import orderBy from 'lodash/orderBy'

// COMPONENTS
import Assignee from './components/Assignee'
import FileBlock from './components/FileBlock'
import Followers from './components/Followers'
import BrandProfile from '../brand_profiles/EditBrandProfile.vue'

export default {
  props: {
    request: Object
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Assignee,
    FileBlock,
    Followers,
    BrandProfile,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      moment,
      orderBy,
      date: null,
      profileDialog: false,
      labelClass: 'font-weight-bold subtle-text caption text-uppercase mb-2',
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    company_profile: function () {
      var company_profile = null

      if (this.request && this.request.company_profile) {
        if (Object.keys(this.$store.getters['brand_profile/profile'](this.request.company_profile)).length) {
          company_profile = this.$store.getters['brand_profile/profile'](this.request.company_profile)
        }
      }
      return company_profile
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'request': {
      handler(request) {
        if (Object.keys(request).length && request.dueDate) {
          this.date = moment(request.dueDate.toDate()).format('YYYY-MM-DD')
        }

        if (request && request.company_profile) {
          this.$store.dispatch('brand_profile/getProfile', request.company_profile)
        }
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    setDeliveryDate(date) {
      let newDate = firebase.firestore.Timestamp.fromDate(new Date(`${date} 00:00:00`))

      this.$store.dispatch('request/updateRequestField', {
        request: this.request,
        field: 'dueDate',
        value: newDate
      })
    },

    // GET USER BRAND PROFILES
    getBrandProfiles(request) {
      this.brandProfiles = []

      db.collection('brand_profiles')
        .where('user', '==', request.user)
        .get()
        .then(snapshot => {
          if (snapshot.size) {
            snapshot.forEach(doc => {
              let profile = doc.data()
              profile.id = doc.id
              profile.ref = doc.ref
              this.brandProfiles.push(profile)
            })
          }
        })
        .catch(error => {
          console.log(error.message)
        })
    }
  }
}
</script>
<template>
  <v-card class="mt-5" flat>
    <div v-if="request && $store.getters['request/haveAccess'](request)">
      <preloader v-if="status.gettingRequest" message="Please wait..." />

      <div v-if="!status.gettingRequest && Object.keys(request).length">
        <!-- HEADER -->
        <div class="d-flex align-center">
          <div class="d-flex">
            <back-square :url="request.status == 'completed' ? 'CompletedRequests' : 'OpenRequests'"/>

            <div>
              <h5 class="ml-1 font-weight-bold title">
                {{ $store.getters['types/getTypeName'](request.requestCategory) }}

                <v-menu 
                  :disabled="!$store.getters['request/canManage']" 
                  v-if="user && user.role !== 'client' " 
                  offset-y
                >
                  <template #activator="{ on }">
                    <v-icon v-on="on" :color="getPriorityColor(request.priority || 'low')" small right>mdi-checkbox-blank-circle</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item @click="updateRequestPriority('high')">
                      <v-list-item-title>High</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateRequestPriority('medium')">
                      <v-list-item-title>Medium</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateRequestPriority('low')">
                      <v-list-item-title>Low</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                
              </h5>


              <div class="grey--text d-flex align-center">
                #{{ request.requestNumber }}

                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn 
                      @click="
                        $copyText(`${$window.origin}${$router.resolve({ name: 'Request', params: { id: request.id } }).href}`)
                        .then(() => $store.dispatch('showSuccess', 'Copied to clipboard'))
                      " 
                      class="ml-2" 
                      v-on="on"
                      small
                      icon 
                    >
                      <v-icon>mdi-link</v-icon>
                    </v-btn>
                  </template>
                  <span class="caption">Copy request URL</span>
                </v-tooltip>
              </div>
            </div>
          </div>

          <v-spacer></v-spacer>

          <v-btn 
            v-if="request.status == 'pending_review' && request.user == user.userid" 
            @click="$store.dispatch('request/updateRequestField', {
              request,
              field: 'status',
              value: 'completed',
            })"
            class="skyblue white--text mr-2"
            raised
          >Work Approved</v-btn>

         <v-btn 
          v-if="request.status == 'pending_review' && request.user == user.userid" 
          @click="$refs.feedbackDialog.showDialog()"
          class="skyblue white--text mr-2"
          raised
        >Leave Feedback</v-btn>
          
        <status-badge :request="request" />
        
      </div>

        <!-- MENU -->

        <div 
          class="d-flex align-center mt-3" 
          style="border-bottom: 1px solid rgba(40,63,151,0.1);"
        >
          <div style="margin-bottom: -1px;">
            <v-tabs
              v-model="tabs"
              color="primary"
              slider-color="primary"
              class="request-tabs"
            >
              <v-tab @click="$router.push({ query: { v: 0 } })">
                <span class="slider-title">Brief</span>
              </v-tab>
              <v-tab @click="$router.push({ query: { v: 1 } })">
                <span class="slider-title">Files</span>
              </v-tab>
              <v-tab @click="$router.push({ query: { v: 2 } })">
                <span class="slider-title">Messages</span>
              </v-tab>
            </v-tabs>
          </div>

          <v-spacer></v-spacer>

          <div class="d-flex align-center">
            <div v-if="$store.getters['request/canManage'] || request.user == user.userid" class="d-flex align-center">
              <v-btn
                @click="$store.dispatch('request/updateRequestField', {
                  request,
                  field: 'status',
                  value: 'paused',
                })"
                v-if="request.status !== 'paused'"
                :loading="status.updatingStatus"
                depressed 
                small
                text
              >
                <v-icon color="info" left>mdi-pause</v-icon> 
                <span class="text-none primary--text" style="letter-spacing: 0 !important;">Pause</span>
              </v-btn>

              <v-btn v-if="$store.getters['request/canManage']" small icon>
                <v-icon @click="confirmDelete()" color="error" small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </div>

        <!-- CONTENT -->
        <v-tabs-items class="overflow-visible" v-model="tabs">
          <v-tab-item 
            v-for="(component, i) in tabComponents"
            reverse-transition="fade"
            transition="fade" 
            :key="i"
          >
            <v-card class="box-shadow mt-10" outlined>
              <component :is="component" :request="request" @review="$refs.feedbackDialog.showDialog()" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <v-alert 
        v-if="!status.gettingRequest && !Object.keys(request).length"
        type="warning"
        border="left"
        text
      >
        Oops! Request not found or it might already have been deleted.
      </v-alert>
    </div>
    <div v-if="!$store.getters['request/haveAccess'](request)">
      No permission
    </div>

    <!-- FEEDBACK DIALOG -->
    <feedback-dialog ref="feedbackDialog" :request="request" />

    <!-- CONFIRM DELETE -->
    <confirm-delete
      @confirmed="deleteConfirmed()"
      @cancel="closeDeleteDialog()"
      :deleting="status.deleting"
      textConfirmation="Request"
      message="Delete request?"
      :show="deleteDialog"
      captcha
    />
  </v-card>
</template>

<script>
// PLUGINS
import { mapState, mapActions } from 'vuex'

// COMPONENTS
import Brief from './Brief'
import Files from './Files'
import Messages from './components/Messages'
import StatusBadge from './components/StatusBadge'
import FeedbackDialog from './components/FeedbackDialog'

export default {
  metaInfo: {
    title: 'Request'
  },

  name: 'Request',

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Brief,
    Files,
    Messages,
    StatusBadge,
    FeedbackDialog,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      tabs: null,
      deleteDialog: false,
      tabComponents: ['Brief', 'Files', 'Messages'],
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.request.status,
    }),

    request: function () {
      return this.$store.getters['request/request'](this.$route.params.id)
    },
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    '$route.query.v': function (value) {
      this.tabs= parseInt(value)
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('request', [
      'getRequest',
      'updateStatus',
      'deleteRequest',
      'updateRequestField',
    ]),

    getPriorityColor(priority) {
      let colors = {
        low: 'dark lighten-5',
        high: 'error darken-1',
        medium: 'success darken-1',
      }

      return colors[priority]
    },

    updateRequestPriority(priority) {
      this.updateRequestField({
        value: priority,
        field: 'priority',
        request: this.request,
      })
    },

    closeDeleteDialog() {
      this.deleteDialog = false
    },

    confirmDelete() {
      this.deleteDialog = true
    },

    deleteConfirmed() {
      Promise.resolve(
        this.deleteRequest(this.request)
      )
      .then(() => {
        this.closeDeleteDialog()
        this.$router.push({ name: 'OpenRequests' })
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getRequest(this.$route.params.id)
    if (this.$route.query.v) {
      this.tabs = parseInt(this.$route.query.v)
    }
    
    if (!this.$store.state.brand_profile.status.companiesFirstLoad) {
      this.$store.dispatch('brand_profile/getMyCompanies')
    }
    
    // this.$watch(
    //   () => [this.user, this.request],
    //   () => {
    //     if (this.user && Object.keys(this.request).length) {
    //       if (this.request.status == 'pending_review' && this.user.userid == this.request.user) {
    //         this.$refs.feedbackDialog.showDialog()
    //       }
    //     }
    //   },
    //   { immediate: true }
    // )
  }
}
</script>

<style lang="scss">

.priority_dot {
  &__high {
    box-shadow: -4px 0 0 var(--v-error-darken1);
  }
  
  &__medium {
    box-shadow: -4px 0 0 var(--v-success-darken1);
  }
  
  &__low {
    box-shadow: -4px 0 0 var(--v-dark-lighten5);
  }
}

</style>
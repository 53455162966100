var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5",attrs:{"flat":""}},[(_vm.request && _vm.$store.getters['request/haveAccess'](_vm.request))?_c('div',[(_vm.status.gettingRequest)?_c('preloader',{attrs:{"message":"Please wait..."}}):_vm._e(),(!_vm.status.gettingRequest && Object.keys(_vm.request).length)?_c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex"},[_c('back-square',{attrs:{"url":_vm.request.status == 'completed' ? 'CompletedRequests' : 'OpenRequests'}}),_c('div',[_c('h5',{staticClass:"ml-1 font-weight-bold title"},[_vm._v(" "+_vm._s(_vm.$store.getters['types/getTypeName'](_vm.request.requestCategory))+" "),(_vm.user && _vm.user.role !== 'client' )?_c('v-menu',{attrs:{"disabled":!_vm.$store.getters['request/canManage'],"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.getPriorityColor(_vm.request.priority || 'low'),"small":"","right":""}},on),[_vm._v("mdi-checkbox-blank-circle")])]}}],null,false,2365035853)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.updateRequestPriority('high')}}},[_c('v-list-item-title',[_vm._v("High")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.updateRequestPriority('medium')}}},[_c('v-list-item-title',[_vm._v("Medium")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.updateRequestPriority('low')}}},[_c('v-list-item-title',[_vm._v("Low")])],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"grey--text d-flex align-center"},[_vm._v(" #"+_vm._s(_vm.request.requestNumber)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","icon":""},on:{"click":function($event){_vm.$copyText(("" + (_vm.$window.origin) + (_vm.$router.resolve({ name: 'Request', params: { id: _vm.request.id } }).href)))
                      .then(function () { return _vm.$store.dispatch('showSuccess', 'Copied to clipboard'); })}}},on),[_c('v-icon',[_vm._v("mdi-link")])],1)]}}],null,false,3367733388)},[_c('span',{staticClass:"caption"},[_vm._v("Copy request URL")])])],1)])],1),_c('v-spacer'),(_vm.request.status == 'pending_review' && _vm.request.user == _vm.user.userid)?_c('v-btn',{staticClass:"skyblue white--text mr-2",attrs:{"raised":""},on:{"click":function($event){return _vm.$store.dispatch('request/updateRequestField', {
            request: _vm.request,
            field: 'status',
            value: 'completed',
          })}}},[_vm._v("Work Approved")]):_vm._e(),(_vm.request.status == 'pending_review' && _vm.request.user == _vm.user.userid)?_c('v-btn',{staticClass:"skyblue white--text mr-2",attrs:{"raised":""},on:{"click":function($event){return _vm.$refs.feedbackDialog.showDialog()}}},[_vm._v("Leave Feedback")]):_vm._e(),_c('status-badge',{attrs:{"request":_vm.request}})],1),_c('div',{staticClass:"d-flex align-center mt-3",staticStyle:{"border-bottom":"1px solid rgba(40,63,151,0.1)"}},[_c('div',{staticStyle:{"margin-bottom":"-1px"}},[_c('v-tabs',{staticClass:"request-tabs",attrs:{"color":"primary","slider-color":"primary"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',{on:{"click":function($event){return _vm.$router.push({ query: { v: 0 } })}}},[_c('span',{staticClass:"slider-title"},[_vm._v("Brief")])]),_c('v-tab',{on:{"click":function($event){return _vm.$router.push({ query: { v: 1 } })}}},[_c('span',{staticClass:"slider-title"},[_vm._v("Files")])]),_c('v-tab',{on:{"click":function($event){return _vm.$router.push({ query: { v: 2 } })}}},[_c('span',{staticClass:"slider-title"},[_vm._v("Messages")])])],1)],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[(_vm.$store.getters['request/canManage'] || _vm.request.user == _vm.user.userid)?_c('div',{staticClass:"d-flex align-center"},[(_vm.request.status !== 'paused')?_c('v-btn',{attrs:{"loading":_vm.status.updatingStatus,"depressed":"","small":"","text":""},on:{"click":function($event){return _vm.$store.dispatch('request/updateRequestField', {
                request: _vm.request,
                field: 'status',
                value: 'paused',
              })}}},[_c('v-icon',{attrs:{"color":"info","left":""}},[_vm._v("mdi-pause")]),_c('span',{staticClass:"text-none primary--text",staticStyle:{"letter-spacing":"0 !important"}},[_vm._v("Pause")])],1):_vm._e(),(_vm.$store.getters['request/canManage'])?_c('v-btn',{attrs:{"small":"","icon":""}},[_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.confirmDelete()}}},[_vm._v("mdi-delete")])],1):_vm._e()],1):_vm._e()])],1),_c('v-tabs-items',{staticClass:"overflow-visible",model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.tabComponents),function(component,i){return _c('v-tab-item',{key:i,attrs:{"reverse-transition":"fade","transition":"fade"}},[_c('v-card',{staticClass:"box-shadow mt-10",attrs:{"outlined":""}},[_c(component,{tag:"component",attrs:{"request":_vm.request},on:{"review":function($event){return _vm.$refs.feedbackDialog.showDialog()}}})],1)],1)}),1)],1):_vm._e(),(!_vm.status.gettingRequest && !Object.keys(_vm.request).length)?_c('v-alert',{attrs:{"type":"warning","border":"left","text":""}},[_vm._v(" Oops! Request not found or it might already have been deleted. ")]):_vm._e()],1):_vm._e(),(!_vm.$store.getters['request/haveAccess'](_vm.request))?_c('div',[_vm._v(" No permission ")]):_vm._e(),_c('feedback-dialog',{ref:"feedbackDialog",attrs:{"request":_vm.request}}),_c('confirm-delete',{attrs:{"deleting":_vm.status.deleting,"textConfirmation":"Request","message":"Delete request?","show":_vm.deleteDialog,"captcha":""},on:{"confirmed":function($event){return _vm.deleteConfirmed()},"cancel":function($event){return _vm.closeDeleteDialog()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-if="request">
    <div class="d-flex align-center gap-4">
      <template v-if="request && request.followers && request.followers.length">
        <v-menu
          v-for="(follower, index) in request.followers"
          open-on-hover
          :key="index"
          offset-y 
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <div 
              class="d-inline-block"
              v-bind="attrs"
              v-on="on"
            >
              <user-photo
                photoSize="thumb"
                :id="follower"
                :size="25"
                v-on="on"
                rounded
              />
            </div>
          </template>

          <v-card>
            <v-card-text class="pa-0">
              <v-list>
                <v-list-item>
                  <v-list-item-avatar rounded ovr>
                    <UserPhoto :size="40" photoSize="thumb" :id="follower" rounded />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="primary--color body-1">{{ $store.getters['users/user'](follower).fullName }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle class="caption">
                        <a :href="`mailto:${$store.getters['users/user'](follower).email}`" class="text-decoration-none">{{ $store.getters['users/user'](follower).email }}</a>
                        <div v-if="$store.getters['users/user'](follower).organization && $store.getters['users/user'](follower).employeeRole">{{ $store.getters['users/user'](follower).employeeRole }}</div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>  
          </v-card>
        </v-menu>
      </template>

      <v-btn
        v-if="$store.getters['request/canChangeStatus'](request)"
        color="grey lighten-2"
        @click="showDialog()"
        class="rounded-sm"
        height="25"
        width="25"
        outlined
        icon
      >
        <v-icon x-small>mdi-plus</v-icon>
      </v-btn>
    </div>

    <employee-dialog 
      :exclude="[request.assignee]"
      :default="request.followers"
      @confirmed="confirmed"
      ref="employeeDialog"
      multiple
    />
  </div>
</template>

<script>
import EmployeeDialog from '../../users/components/EmployeeDialog'

export default {
  props: {
    request: Object,
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    EmployeeDialog
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    showDialog() {
      this.$refs.employeeDialog.showDialog()
    },

    closeDialog() {
      this.$refs.employeeDialog.closeDialog()
    },

    confirmed(values) {
      this.$refs.employeeDialog.loading = true
      let prevValues = this.request.followers ? JSON.parse(JSON.stringify(this.request.followers)) : []

      Promise.resolve(
        this.$store.dispatch('request/updateRequestField', {
          request: this.request,
          value: values,
          field: 'followers',
        })
      )
      .then(() => {
        this.$refs.employeeDialog.loading = false
        this.$refs.employeeDialog.selectedEmployees = []
        this.closeDialog()

        values.forEach(id => {
          if (!prevValues.includes(id))
            this.sendEmailNotification(id)
        })
      })
    },

    sendEmailNotification(id) {
      let user = this.$store.getters['users/employee'](id)

      this.$store.dispatch('email/sendMail', {
        to: user.email,
        subject: 'You have been added as a follower',
        message: `
          Hi ${user.fullName},<br/><br/>
          You have been added as a follower to a request.<br/>
          Click <a href="${window.origin}${this.$router.resolve({ name: 'Request', params: { id: this.request.id } }).href}">here</a> for more details.
        `
      })
    }
  }
}
</script>
<template>
  <div v-if="request" class="d-flex align-center gap-10">
    <template v-if="request.assignee">
      <v-list-item 
        @click.prevent="$store.getters['request/canManage'] ? showDialog() : ''"
        :style="{ minHeight: '30px !important' }"
        class="px-1"
        dense
      >
        <v-list-item-avatar class="overflow-visible mr-1 my-0" height="30" rounded>
          <UserPhoto :size="30" photoSize="thumb" :id="request.assignee" rounded />
        </v-list-item-avatar>
        <v-list-item-title :class="{ 'caption blue-grey--text' : small }">
          {{ $store.getters['users/employee'](request.assignee).fullName }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-else>
      <v-list-item 
        @click.prevent="$store.getters['request/canManage'] ? showDialog() : ''"
        :style="{ minHeight: '30px !important' }"
        class="px-1 pl-0"
        dense
      >
        <v-list-item-avatar class="overflow-visible mr-1 my-0" height="30" rounded>
          <v-icon size="34" color="grey lighten-1">mdi-account-box-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-title class="caption grey--text">
          Not assigned
        </v-list-item-title>
      </v-list-item>
    </template>

    <!-- EMPLOYEE DIALOG -->
    <employee-dialog
      ref="employeeDialog" 
      @confirmed="confirmed"
      :default="request.assignee"
      :exclude="request.followers || []"
    />
  </div>
</template>

<script>
import EmployeeDialog from '../../users/components/EmployeeDialog.vue'

export default {
  props: {
    small: Boolean,
    request: Object,
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    EmployeeDialog
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    showDialog() {
      this.$refs.employeeDialog.showDialog()
    },

    closeDialog() {
      this.$refs.employeeDialog.closeDialog()
      this.$refs.employeeDialog.loading = false
    },

    confirmed(employee) {
      this.$refs.employeeDialog.loading = true
      
      Promise.resolve(
        this.$store.dispatch('request/updateRequestField', {
          value: employee,
          field: 'assignee',
          request: this.request,
        })
      )
      .then(() => {
        this.closeDialog()
        
        let user = this.$store.getters['users/user'](this.request.assignee)

        this.$store.dispatch('email/sendMail', {
          to: user.email,
          subject: 'A request has been assigned to you',
          message: `
            Hi ${user.fullName},<br/><br/>
            A request has been assigned to you.<br/>
            Click <a href="${window.origin}${this.$router.resolve({ name: 'Request', params: { id: this.request.id } }).href}">here</a> for more details.
          `
        })
      })
    }
  }
}
</script>
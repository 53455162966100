<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.content"
    scrollable
  >
    <close-btn @click="closeDialog()" overflow />

    <v-card>
      <v-card-title class="flex-wrap">
        <div class="font-weight-bold secondary--text flex-grow-1">Select a Canned Response</div>
      </v-card-title>
      <v-card-subtitle class="mt-1">
        <v-text-field
          class="flex-grow-1 field-shadow"
          append-icon="mdi-magnify"
          placeholder="Search"
          v-model="search"
          hide-details
          clearable
          outlined
          rounded
          dense
        ></v-text-field>
      </v-card-subtitle>
      <v-card-text>
        <v-fade-transition group>
          <v-hover
            v-for="response in confResponses"
            :key="response.id"
            v-slot="{ hover }"
          >
            <v-card
              @click="
                $emit('selected', response)
                closeDialog()
              "
              :class="{ 'box-shadow primary': hover }"
              class="mb-2 transition"
              :dark="hover"
              outlined
            >
              <v-card-text class="py-2">{{ response.name }}</v-card-text>
            </v-card>
          </v-hover>
        </v-fade-transition>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { orderBy } from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      search: null,
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      responses: state => state.canned_responses.responses,
      status: state => state.canned_responses.status,
    }),

    confResponses: function () {
      let responses = this.responses.filter(response => {
        if (this.search) {
          return response.name.toLowerCase().includes(this.search.toLowerCase())
        }
        else return true
      })

      responses = responses.filter(response => {
        return response.type == this.type
      })

      responses = orderBy(responses, 'name', 'asc')

      return responses
    },

    type: function () {
      return this.$route.name == 'Request' ? 'request' : 'order'
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('canned_responses', [
      'getCannedResponses'
    ]),

    showDialog() {
      this.dialog = true
    },
    
    closeDialog() {
      this.dialog = false
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstLoad)
      this.getCannedResponses(this.type)
  }
}
</script>
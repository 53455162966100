<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.content"
  >
    <close-btn @click="closeDialog()" overflow />

    <v-card class="pa-3">
      <v-card-title class="justify-center secondary--text pb-0">
        Please Leave Us A Feedback
      </v-card-title>
      <v-card-text>
        <div class="text-center mb-5">
          Select a rating and add a note if you have any feedback.
        </div>

        <v-row class="mb-5" dense>
          <v-col v-for="(rating, index) in ratings" :key="index" >
            <v-hover v-slot="{ hover }">
              <v-card 
                @click="selectedRating = rating.value"
                :style="{ borderColor: selectedRating == rating.value ? 'var(--v-primary-base) !important' : 'inherit' }" 
                class="pa-5 text-center rounded-lg transition fill-height d-flex align-center justify-center flex-wrap" 
                :class="{ 'box-shadow': hover }" 
                outlined
              >
                <v-img v-if="rating.img" :src="require(`@/assets/${rating.img}`)" class="mx-auto mb-2" width="50" />
                <div class="grey--text lighten-1" v-html="rating.text"></div>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>

        <div class="secondary--text font-weight-bold mb-2">
          Write a message (optional)
        </div>
        <v-textarea
          v-model="ratingMessage"
          outlined
        ></v-textarea>

        <v-row dense>
          <v-col>
            <v-btn
              @click="checkRating()" 
              :loading="status.creating"
              color="gradient accent" 
              :disabled="!selectedRating"
              block
            >Submit</v-btn>
          </v-col>
          <v-col>
            <v-btn 
              @click="closeDialog()"
              :disabled="status.creating"
              color="primary" 
              outlined
              block 
            >Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import db from '@/firebase/init'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    request: Object,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      dialog: false,
      ratingMessage: null,
      selectedRating: null,
      ratings: [
        {
          text: 'It was great!',
          img: 'great_rating.svg',
          value: 'happy',
        },
        {
          text: 'It was ok.',
          img: 'ok_rating.svg',
          value: 'okay',
        },
        {
          text: 'It was bad!',
          img: 'bad_rating.svg',
          value: 'bad'
        },
        {
          text: 'Close <br> No Rating',
          value: 'no_rating'
        },
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.feedbacks.status
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('feedbacks', [
      'createFeedback'
    ]),

    showDialog() {
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false

      setTimeout(() => {
        this.selectedRating = null
        this.ratingMessage = null
      }, 1000)
    },

    checkRating() {
      if (this.selectedRating) {
        Promise.resolve(
          this.createFeedback({
            order: this.request.id,
            rating: this.selectedRating,
            message: this.ratingMessage,
            assignee: this.request.assignee,
            type: 'request'
          })
          .then(() => {
            this.$store.dispatch('request/updateRequestField', {
              request: this.request,
              field: 'status',
              value: 'completed',
              silent: true,
            })

            var settingId, text

            if (this.selectedRating == 'bad') {
              settingId = 'bad'
              text = `${this.$store.getters['users/user'](this.request.user).fullName} has given us a *bad* rating! _"${ this.ratingMessage || 'The customer did not leave a comment' }"_. ${window.origin}${this.$router.resolve({ name: 'Request', params: { id: this.request.id } }).href}`
            }
            else if (['okay', 'happy'].includes(this.selectedRating)) {
              settingId = 'goodFeedback'
              text = `${this.$store.getters['users/user'](this.request.user).fullName} has given us a *great* rating! _"${ this.ratingMessage || 'The customer did not leave a comment' }"_. Let's all post your congratulations to *${this.$store.getters['users/user'](this.request.assignee).chatId ? '<users/'+this.$store.getters['users/user'](this.request.assignee).chatId+'>' : this.$store.getters['users/user'](this.request.assignee).fullName}*! ${window.origin}${this.$router.resolve({ name: 'Request', params: { id: this.request.id } }).href}`
            }

            if (settingId) {
              db.collection('generalSettings')
              .doc(settingId).get()
              .then((doc) => {
                let url = doc.data().value
  
                Promise.resolve(
                  this.$store.dispatch('webhook/googleChat', {
                    url, text
                  })
                )
                .then(() => {
                  this.closeDialog()
                })
              })
            }
            else {
              this.closeDialog()
            }
          })
        )
      }
      else {
        this.$store.dispatch('showError', 'Please select a rating')
      }
    }
  }
}
</script>
<template>
  <v-sheet class="align-top d-flex gap-7 flex-wrap pa-10">
    <v-sheet width="100%">
      <v-alert class="mb-0" type="info" v-if="!files.length" border="left" text>
        No files found
      </v-alert>
    </v-sheet>
    
    <template v-if="files.length">
      <file-block
        v-for="file in files"
        :key="file.key"
        :file="file"
      />    
    </template>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'
import FileBlock from './components/FileBlock'

export default {
  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      files: state => state.request.files
    }),
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    FileBlock
  }
}
</script>
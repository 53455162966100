<template>
  <div v-if="employees.length && show" id="messageField" class="mb-5" :class="{ 'note-field' : note }">
    <ckeditor
      :editor="editor"
      v-model="editorData"
      :config="editorConfig"
    ></ckeditor>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import { camelCase, upperFirst } from 'lodash'

import CKEditor from '@ckeditor/ckeditor5-vue2'
import List from '@ckeditor/ckeditor5-list/src/list'
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link"
import Mention from '@ckeditor/ckeditor5-mention/src/mention'
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold"
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock'
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic"
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph"
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials"
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor"


export default {
  name: "messageField",

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    note: Boolean,
    value: String,
    companyProfile: String,
  },

  model: {
    event: 'updateModel'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      owner: null,
      profile: {},
      show: false,
      editorData: '',
      forceShow: Boolean,
      editor: ClassicEditor,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      users: (state) => state.users.users,
      employees: (state) => state.users.employees,
      profiles: (state) => state.brand_profile.profiles,
      firstLoad: (state) => state.users.status.firstLoadEmployees,
    }),

    getFeeds: function () {
      var items = []

      this.employees.forEach(item => {
        items.push({
          id: `@${ item.fullName }`,
          userId: item.userid,
          email: item.email
        })
      })
      
      if (!this.note) {
        if (this.owner) {
          let owner = this.users.find(u => u.userid === this.owner)
          
          if (owner) {
            items.push({
              id: `@${ owner.fullName }`,
              userId: owner.userid,
              email: owner.email
            })
          }
        }
  
        this.members.forEach(member => {
          items.push({
            id: `@${ member.fullName }`,
            userId: member.userid,
            email: member.email
          })
        })
      }

      let currentUser = items.find((item) => item.userId === this.user.userid)
      
      
      if (currentUser) {
        items.splice(items.indexOf(currentUser), 1)
      }

      return items
    },
    
    editorConfig: function () {
      var config = {
        plugins: [
          List,
          Mention,
          CodeBlock,
          BoldPlugin,
          LinkPlugin,
          ItalicPlugin,
          ParagraphPlugin,
          EssentialsPlugin,
          MentionCustomization,
        ],

        toolbar: {
          items: ["bold", "italic", "bulletedList", "numberedList", "link", "codeBlock", "undo", "redo"],
        },

        mention: {
          feeds: [
            {
              marker: '@',
              feed: this.getFeeds,
              minimumCharacters: 0,
              itemRenderer: customItemRenderer,
            }
          ]
        },

        codeBlock: {
          languages: [
            // Use the "php-code" class for PHP code blocks.
            { language: 'php', label: 'PHP', class: 'php-code' },
            // Note that only the first ("js") class will determine the language of the block when loading data.
            { language: 'javascript', label: 'JavaScript', class: 'js javascript js-code' },
            // Note that only the first ("js") class will determine the language of the block when loading data.
            { language: 'css', label: 'CSS', class: 'css-code' },
          ]
        }
      }

      // MENTION CUSTOMIZATION
      function MentionCustomization(editor) {
        // The upcast converter will convert view <a class="mention" href="" data-user-id="">
        // elements to the model 'mention' text attribute.
        editor.conversion.for( 'upcast' ).elementToAttribute( {
          view: {
            key: 'data-mention',
            classes: 'mention',
            attributes: {
              'data-user-id': true
            }
          },
          model: {
            key: 'mention',
            value: viewItem => {
              // The mention feature expects that the mention attribute value
              // in the model is a plain object with a set of additional attributes.
              // In order to create a proper object use the toMentionAttribute() helper method:
              const mentionAttribute = editor.plugins.get( 'Mention' ).toMentionAttribute( viewItem, {
                  // Add any other properties that you need.
                  userId: viewItem.getAttribute( 'data-user-id' )
              })

              return mentionAttribute
            }
          },
          converterPriority: 'high'
        });

        // Downcast the model 'mention' text attribute to a view <a> element.
        editor.conversion.for( 'downcast' ).attributeToElement( {
            model: 'mention',
            view: ( modelAttributeValue, { writer } ) => {
                // Do not convert empty attributes (lack of value means no mention).
                if ( !modelAttributeValue ) {
                  return
                }

                return writer.createAttributeElement( 'strong', {
                  class: 'mention',
                  'data-mention': modelAttributeValue.id,
                  'data-user-id': modelAttributeValue.userId,
                }, {
                  // Make mention attribute to be wrapped by other attribute elements.
                  priority: 20,
                  // Prevent merging mentions together.
                  id: modelAttributeValue.uid
                })
            },
            converterPriority: 'high'
        } )
      }

      // ITEM RENDERER

      function customItemRenderer( item ) {
        const itemElement = document.createElement( 'div' )

        itemElement.classList.add( 'custom-item' )
        itemElement.textContent = `${ item.id } (${ item.email })`

        return itemElement
    }

      return config
    },

    members: function () {
      let members = []
      
      if (this.profile && this.profile.members && this.profile.members.length) {
        this.profile.members.forEach(m => {
          let member = this.users.find(user => user.userid === m)
          
          if (member) {
            members.push(member)
          }
        })
      }

      return members
    }
  }, 

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'editorData': function (value) {
      this.updateModelValue(value)
    },

    'companyProfile': {
      handler(value) {
        if (value) {
          this.$store.dispatch('brand_profile/getProfile', value)
        }
      },
      immediate: true
    },

    'profiles': {
      handler(profiles) {
        this.profile = profiles.find(p => p.id === this.companyProfile)
        
        if (this.profile) {
          this.owner = this.profile.user
          if (!this.profile.members || (this.profile.members && !this.profile.members.length)) {
            this.show = true
          }
        }
      },
      immediate: true
    },

    'members': function () {
      if (this.profile && this.profile.members && this.profile.members.length) {
        if (this.members.length >= this.profile.members.length) {
          this.show = true
        }
      }
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    updateModelValue(value) {
      this.$emit('updateModel', value)
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    ckeditor: CKEditor.component
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.firstLoad) {
      this.$store.dispatch('users/getEmployees')
    }

    if (this.forceShow) {
      this.show = true
    }

    if (this.value) {
      this.editorData = this.value
    }
  }
}
</script>

<style>
:root {
  --ck-color-mention-background: hsla(220, 100%, 83%, 0.4);
  --ck-color-mention-text: hsl(221, 77%, 42%);
}

.note-field .ck.ck-editor__main > .ck-editor__editable {
  background: #f9fbe7 !important;
}
</style>